/*
 * This file is imported by bootstrap.scss
 * Use it to override specific styles when you cannot change a variable.
*/

@mixin properties() {
  .dropdown {
    &.show {
      background-color: $color-primary !important;
    }

    &-menu {
      box-shadow: 0 2px 4px rgb(26 26 26 / 20%);
      border-radius: 3px 0 3px 3px;
      right: -1px !important;
      top: -3px !important;
      border: 0;
      padding: 0;
      width: fit-content;
    }

    &-toggle {
      &:after {
        display: none;
      }
    }
  }

  .btn {
    &-language {
      font-family: poppinsSemiBold;
      padding-right: $spacing-sm;
      justify-content: center;
      border-radius: 0;
      width: 5.375rem;
      height: 100%;
    }

    &:disabled {
      border-color: transparent;
    }
  }

  .accordion {
    &-item {
      border: none;
    }

    &-button {
      background-color: transparent;
      color: $color-gray !important;

      &:focus {
        border-color: none;
        box-shadow: none;
      }

      &:after {
        background-position: center;
        border-radius: 50%;
        height: 1.875rem;
        width: 1.875rem;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      &:not(.collapsed) {
        color: initial;
        background-color: initial;
        box-shadow: none;

        &:after {
          top: 20%;
        }
      }
    }

    &-header {
      margin-right: 0 !important;
    }
  }
}

/* stylelint-disable */
@mixin bootstrapOverrides($parent: null) {
  @if $parent {
    #{$parent} {
      @include properties;
    }
  } @else {
    @include properties;
  }
}
/* stylelint-enable */

@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 95vw;
  }
}

@media (max-width: 1025px) {
  .btn-language {
    width: $spacing-xxxl;
  }
}
