*:not(.custom-scrollbar) {
  $scrollbar-bg: transparent; // space below the scrollbar
  $scrollbar-th: #e5e5e5; // draggable scrollbar itself
  $width: $scrollbar-width;

  /* Works on Firefox */
  scrollbar-width: thin;
  scrollbar-color: $scrollbar-th $scrollbar-bg;

  /* Works on Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    background: $scrollbar-bg;
    height: 0;
    width: $width;
  }

  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-track-piece {
    background: $scrollbar-bg;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar-th;
    background-clip: content-box;
    border-radius: $width;
    border: 1px solid transparent;

    &:hover {
      /* stylelint-disable-next-line */
      background-color: darken($scrollbar-th, 10%);
    }
  }
}
