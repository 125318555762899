// Colors
$color-light: #ffffff;
$color-gray-light: #edf0f2;
$color-gray: #8a9dad;
$color-gray-dark: #303030;
$color-dark: #000000;
$color-dark-light: #252e35;
$color-primary-light: #f0f7ff;
$color-primary-lighter: #2596be;
$color-primary-bright: #2990ff;
$color-primary-brighter: #7abaff;
$color-primary: #007bff;
$color-primary-dark: #0060c7;
$color-primary-darker: #00458f;
$color-secondary: #0f8697;
$color-secondary-lighter: #48bd77;
$color-secondary-neutral: #8a9dad;
$color-secondary-neutral-lighter: #f6f8f9;
$color-warning: #f2b75f;
$color-error: #f25f64;
$color-activated: #3772ff;
$color-separator: #dfe5eb;
$color-secondary-blue: #e0efff;
$color-cream: #ffcb7d;
$color-sun: #fbdd40;
$color-red: #ff4141;
$color-emerald: #3ea366;
$color-mint: #64d190;
$color-future: #a3cfff;
$color-aligner-active: #80e8a9;
$color-aligner-passive: #a8b6c2;
$color-overmovement: #ff4141;
$color-overmovement-alt: #ff8787;
$color-oppositemovement: #fc8f42;
$color-no-movement: #dfe5eb;
$color-space: #f2b75f;

// Primary
$color-primary-200: #cce5ff;

// Secondary
$color-neutral: #647b8f;
$color-neutral-100: #edf0f2;
$color-neutral-300: #c9d2d9;
$color-danger: #f58489;

// Spacing
$spacing-xxs: 0.2rem;
$spacing-xs: 0.5rem;
$spacing-s: 1rem;
$spacing-sm: 1.5rem;
$spacing-m: 2rem;
$spacing-l: 2.5rem;
$spacing-xl: 3rem;
$spacing-xxl: 3.5rem;
$spacing-xxxl: 4rem;

// FontFamily
$ff-poppins-light: 'poppinsLight';
$ff-poppins-regular: 'poppinsRegular';
$ff-poppins-semibold: 'poppinsSemiBold';
$ff-poppins-bold: 'poppinsBold';

// FontSizes
$font-size-xxs: 0.57rem; // 9px
$font-size-xs: 0.6875rem; // 11px
$font-size-s: 0.8125rem; // 13px
$font-size-sm: 0.875rem; // 14px
$font-size-m: 1rem; // 16px
$font-size-l: 1.25rem; // 20px

// FontWeight
$font-weight-regular: 400;
$font-weight-bold: 600;

// Z-index
$z-index-s: 1;
$z-index-m: 10;
$z-index-l: 20;
$z-index-xl: 50;
$z-index-xxl: 100;

// Time
$ani-fast: 0.15s;
$ani-normal: 0.35s;
$ani-slow: 0.5s;

// Override boostrap variables
$modal-inner-padding: 1.5rem;
$secondary: #252e35;

// Global variables
$scrollbar-width: 10px;
$ev-panel-width: 1.18rem;
$ev-panel-space: 3px;
$ev-panel-piece-h: 1.3rem;
$dropdown-menu-min-width: 13rem;
$panel-tools-height: 100px;
$panel-tools-movement-height: 130px;
$panel-header-height: 25px;
$panel-info-width: 35.625rem;

// Media sizes
$media-xs: 740px;
$media-s: 768px;
$media-ms: 988px; // Size of 3D vertical canvas while comparing
$media-m: 1024px;
$media-l: 1280px;
$media-xl: 1440px;
$media-xxl: 1680px;
$media-2k: 2048px;
$media-4k: 3840px;

.togglecomparetreatments {
  border: 1px solid $color-separator;
  border-right: 0;
  border-left: 0;
  font-size: $font-size-s;
  font-family: $ff-poppins-regular;
  min-width: 20rem;
  max-width: 20rem;
  width: 100%;
  overflow: hidden;
  padding-bottom: 0.5rem;

  &-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $spacing-l;
    padding: 0.25rem 0.75rem;
    border-bottom: 1px solid $color-separator;
  }

  &-item {
    padding: 0.25rem 0.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: $font-size-s;
    font-weight: $font-weight-bold;
    width: 100%;
    gap: $spacing-xs;
  }

  &-container {
    max-height: 18rem;
    overflow-y: auto;
  }
}
