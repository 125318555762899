// Colors
$color-light: #ffffff;
$color-gray-light: #edf0f2;
$color-gray: #8a9dad;
$color-gray-dark: #303030;
$color-dark: #000000;
$color-dark-light: #252e35;
$color-primary-light: #f0f7ff;
$color-primary-lighter: #2596be;
$color-primary-bright: #2990ff;
$color-primary-brighter: #7abaff;
$color-primary: #007bff;
$color-primary-dark: #0060c7;
$color-primary-darker: #00458f;
$color-secondary: #0f8697;
$color-secondary-lighter: #48bd77;
$color-secondary-neutral: #8a9dad;
$color-secondary-neutral-lighter: #f6f8f9;
$color-warning: #f2b75f;
$color-error: #f25f64;
$color-activated: #3772ff;
$color-separator: #dfe5eb;
$color-secondary-blue: #e0efff;
$color-cream: #ffcb7d;
$color-sun: #fbdd40;
$color-red: #ff4141;
$color-emerald: #3ea366;
$color-mint: #64d190;
$color-future: #a3cfff;
$color-aligner-active: #80e8a9;
$color-aligner-passive: #a8b6c2;
$color-overmovement: #ff4141;
$color-overmovement-alt: #ff8787;
$color-oppositemovement: #fc8f42;
$color-no-movement: #dfe5eb;
$color-space: #f2b75f;

// Primary
$color-primary-200: #cce5ff;

// Secondary
$color-neutral: #647b8f;
$color-neutral-100: #edf0f2;
$color-neutral-300: #c9d2d9;
$color-danger: #f58489;

// Spacing
$spacing-xxs: 0.2rem;
$spacing-xs: 0.5rem;
$spacing-s: 1rem;
$spacing-sm: 1.5rem;
$spacing-m: 2rem;
$spacing-l: 2.5rem;
$spacing-xl: 3rem;
$spacing-xxl: 3.5rem;
$spacing-xxxl: 4rem;

// FontFamily
$ff-poppins-light: 'poppinsLight';
$ff-poppins-regular: 'poppinsRegular';
$ff-poppins-semibold: 'poppinsSemiBold';
$ff-poppins-bold: 'poppinsBold';

// FontSizes
$font-size-xxs: 0.57rem; // 9px
$font-size-xs: 0.6875rem; // 11px
$font-size-s: 0.8125rem; // 13px
$font-size-sm: 0.875rem; // 14px
$font-size-m: 1rem; // 16px
$font-size-l: 1.25rem; // 20px

// FontWeight
$font-weight-regular: 400;
$font-weight-bold: 600;

// Z-index
$z-index-s: 1;
$z-index-m: 10;
$z-index-l: 20;
$z-index-xl: 50;
$z-index-xxl: 100;

// Time
$ani-fast: 0.15s;
$ani-normal: 0.35s;
$ani-slow: 0.5s;

// Override boostrap variables
$modal-inner-padding: 1.5rem;
$secondary: #252e35;

// Global variables
$scrollbar-width: 10px;
$ev-panel-width: 1.18rem;
$ev-panel-space: 3px;
$ev-panel-piece-h: 1.3rem;
$dropdown-menu-min-width: 13rem;
$panel-tools-height: 100px;
$panel-tools-movement-height: 130px;
$panel-header-height: 25px;
$panel-info-width: 35.625rem;

// Media sizes
$media-xs: 740px;
$media-s: 768px;
$media-ms: 988px; // Size of 3D vertical canvas while comparing
$media-m: 1024px;
$media-l: 1280px;
$media-xl: 1440px;
$media-xxl: 1680px;
$media-2k: 2048px;
$media-4k: 3840px;

.photocarousel {
  display: flex;
  flex-direction: column;
  height: calc(100vh - $spacing-xxl);

  &-header {
    height: $spacing-l;
    background-color: $color-light;
    border-top: 1px solid $color-gray-light;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    z-index: $z-index-s;

    &_title {
      font-family: poppinsSemiBold;
    }

    &_counter {
      border: 1px solid $color-gray;
      border-radius: 1.875rem;
      text-align: center;
      line-height: 2rem;
      min-width: 3rem;
      height: 2rem;
      color: $color-secondary-neutral;
      font-family: poppinsSemiBold;
      font-size: 0.8rem;
      letter-spacing: 3px;
    }

    &_controls {
      cursor: pointer;

      img {
        margin-left: 2rem;
      }
    }
  }

  &-arrow {
    border: 2px solid $color-secondary-neutral;
    border-radius: 200px;
    width: 3rem;
    text-align: center;
    height: 3rem;
    line-height: 2.7rem;
    background-color: $color-gray-dark;

    &:hover {
      background-color: $color-primary;
      border: 2px solid $color-light;
      cursor: pointer;
    }
  }

  &-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: calc(100vh - $spacing-xxl - $spacing-l);
    background-color: $color-dark;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .carousel {
    display: flex;
    justify-content: center;
    width: 100%;

    &-inner {
      height: 80vh !important;
      width: 80% !important;
    }

    &-item.active,
    &-item-start {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    img {
      max-width: 100%;
    }
  }
}
