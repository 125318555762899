@font-face {
  font-family: poppinsRegular;
  src: url('../../assets/fonts/poppins/Poppins-Regular.ttf');
}

@font-face {
  font-family: poppinsLight;
  src: url('../../assets/fonts/poppins/Poppins-Light.ttf');
}

@font-face {
  font-family: poppinsSemiBold;
  src: url('../../assets/fonts/poppins/Poppins-SemiBold.ttf');
}

@font-face {
  font-family: poppinsBold;
  src: url('../../assets/fonts/poppins/Poppins-Bold.ttf');
}
