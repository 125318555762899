// Colors
$color-light: #ffffff;
$color-gray-light: #edf0f2;
$color-gray: #8a9dad;
$color-gray-dark: #303030;
$color-dark: #000000;
$color-dark-light: #252e35;
$color-primary-light: #f0f7ff;
$color-primary-lighter: #2596be;
$color-primary-bright: #2990ff;
$color-primary-brighter: #7abaff;
$color-primary: #007bff;
$color-primary-dark: #0060c7;
$color-primary-darker: #00458f;
$color-secondary: #0f8697;
$color-secondary-lighter: #48bd77;
$color-secondary-neutral: #8a9dad;
$color-secondary-neutral-lighter: #f6f8f9;
$color-warning: #f2b75f;
$color-error: #f25f64;
$color-activated: #3772ff;
$color-separator: #dfe5eb;
$color-secondary-blue: #e0efff;
$color-cream: #ffcb7d;
$color-sun: #fbdd40;
$color-red: #ff4141;
$color-emerald: #3ea366;
$color-mint: #64d190;
$color-future: #a3cfff;
$color-aligner-active: #80e8a9;
$color-aligner-passive: #a8b6c2;
$color-overmovement: #ff4141;
$color-overmovement-alt: #ff8787;
$color-oppositemovement: #fc8f42;
$color-no-movement: #dfe5eb;
$color-space: #f2b75f;

// Primary
$color-primary-200: #cce5ff;

// Secondary
$color-neutral: #647b8f;
$color-neutral-100: #edf0f2;
$color-neutral-300: #c9d2d9;
$color-danger: #f58489;

// Spacing
$spacing-xxs: 0.2rem;
$spacing-xs: 0.5rem;
$spacing-s: 1rem;
$spacing-sm: 1.5rem;
$spacing-m: 2rem;
$spacing-l: 2.5rem;
$spacing-xl: 3rem;
$spacing-xxl: 3.5rem;
$spacing-xxxl: 4rem;

// FontFamily
$ff-poppins-light: 'poppinsLight';
$ff-poppins-regular: 'poppinsRegular';
$ff-poppins-semibold: 'poppinsSemiBold';
$ff-poppins-bold: 'poppinsBold';

// FontSizes
$font-size-xxs: 0.57rem; // 9px
$font-size-xs: 0.6875rem; // 11px
$font-size-s: 0.8125rem; // 13px
$font-size-sm: 0.875rem; // 14px
$font-size-m: 1rem; // 16px
$font-size-l: 1.25rem; // 20px

// FontWeight
$font-weight-regular: 400;
$font-weight-bold: 600;

// Z-index
$z-index-s: 1;
$z-index-m: 10;
$z-index-l: 20;
$z-index-xl: 50;
$z-index-xxl: 100;

// Time
$ani-fast: 0.15s;
$ani-normal: 0.35s;
$ani-slow: 0.5s;

// Override boostrap variables
$modal-inner-padding: 1.5rem;
$secondary: #252e35;

// Global variables
$scrollbar-width: 10px;
$ev-panel-width: 1.18rem;
$ev-panel-space: 3px;
$ev-panel-piece-h: 1.3rem;
$dropdown-menu-min-width: 13rem;
$panel-tools-height: 100px;
$panel-tools-movement-height: 130px;
$panel-header-height: 25px;
$panel-info-width: 35.625rem;

// Media sizes
$media-xs: 740px;
$media-s: 768px;
$media-ms: 988px; // Size of 3D vertical canvas while comparing
$media-m: 1024px;
$media-l: 1280px;
$media-xl: 1440px;
$media-xxl: 1680px;
$media-2k: 2048px;
$media-4k: 3840px;

.timeline {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2.5rem;
  transition: height ease-out 0.15s, top ease-out 0.15s;
  display: flex;
  align-items: center;
  background-color: $color-light;
  box-shadow: inset 0 1px 0 rgba($secondary, 0.1);

  &.is-overposition {
    height: 4rem;
  }

  &.main-timeline {
    &.vertical {
      top: calc(50% - 2.5rem);
      margin-top: 6px;

      &.is-overposition {
        top: calc(50% - 4rem);
      }
    }
  }

  &.secondary-timeline {
    left: calc(100% / 2);

    &.vertical {
      bottom: 0;
      left: 0;
    }
  }

  &.extraheight {
    height: $spacing-xxxl;
  }

  &-version {
    $size: 1.5rem;

    position: absolute;
    display: flex;
    gap: 0.5rem;
    height: $size;
    justify-content: center;
    top: calc($size + 0.5rem) * -1;
    color: $color-secondary-neutral;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
    max-width: 100%;

    & svg {
      path {
        fill: $color-secondary-neutral;
      }
    }

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &-separator {
    border-left: 1px solid $color-separator;
    height: 100%;
  }

  &-button {
    margin: 0 0.25rem;
    padding: 0;
    border: 0;
    background-color: transparent;
    outline: none;
    min-width: 2.25rem;

    &.is-active path,
    &:hover path {
      fill: $color-primary;
    }

    &--reverse {
      &.is-active path,
      &.is-active:hover path {
        fill: $color-light;
      }
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  &-steps {
    display: flex;
    padding: 0 0.75rem;

    &.is-finalposition {
      justify-content: flex-start;
    }

    &.is-publish {
      height: 100%;
    }
  }

  &-step {
    width: 100%;
    margin: 0.5rem 0.25rem;
    border: 0;
    background-color: transparent;
    padding: 0;
    display: flex;
    flex-direction: column;

    &--publish {
      background-color: $color-no-movement;
    }
  }

  &.comparer-activated {
    width: 50%;

    &.vertical {
      width: 100%;
    }

    & .timeline-button {
      margin: 0.1rem;
    }

    & .timeline-steps {
      padding: 0 0.1rem;
    }

    &.horizontal {
      & .timeline-step {
        margin: 0.5rem 0.1rem;
      }
    }
  }

  $-line-h: 4px;

  &-step_arch {
    width: 100%;
    height: $-line-h;
    border-top: $-line-h solid $color-gray;
    margin-bottom: 1px;
    position: relative;

    &.is-empty {
      border-top-color: transparent;
    }

    &.active-aligner {
      border-top-color: $color-aligner-active;
    }

    &.passive-aligner {
      border-top-color: $color-aligner-passive;
    }

    &.is-modified {
      border-top-color: $color-warning;
    }

    &.is-new {
      border-top-color: $color-separator;
    }

    &.is-modifiying {
      border-top-color: $color-red;
    }

    &.is-publish {
      margin-bottom: 3px;
    }

    &.is-publish-one-arch {
      margin-top: 3px;
    }
  }

  &-step_lines {
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    top: -$-line-h;
  }

  &-step_line_state {
    width: 33.33%;
    height: 100%;
    border-top: $-line-h solid transparent;

    &.is-distance {
      &:nth-child(2) {
        border-top-color: $color-primary;
      }
    }

    &.is-attachement {
      border-top-color: $color-danger;
    }
  }

  &-step_button {
    width: 100%;
    height: 100%;
    padding: 0;
    border: none;
    min-height: 1.2rem;
    background: transparent;

    &:disabled {
      cursor: not-allowed;
    }

    &.is-keystep {
      border: 2px solid $color-primary-dark;
    }

    &.is-stepwithnoautorecalc {
      background-color: $color-error;
    }
  }

  &-step_text {
    font-family: $ff-poppins-semibold;
    font-size: $font-size-xs;
    padding: 2px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1px;

    &.is-active {
      background-color: $color-primary;
      color: $color-light;

      &.is-keystep {
        background-color: $color-primary-bright;
      }

      &.has-minor-version {
        background-color: $color-mint;
        color: $color-dark;
      }

      &.is-comparing-step {
        background-color: $color-primary-lighter;
      }
    }

    &.is-multiselected {
      background-color: $color-primary-brighter;
      color: $color-light;
    }

    &.has-minor-version {
      border: 2px solid $color-mint;
      color: $color-emerald;
    }
  }

  &-overposition {
    &--activated {
      background-color: $color-primary;
      height: 100%;
      line-height: 2.5rem;
    }
  }

  .finalpositionstage-timeline {
    max-width: $spacing-xl;
  }
}
